import React from 'react'
import './AsideModal.css'
import { createPortal } from 'react-dom'

function AsideModal({ visible, setVisible, title, children, onHide, size }) {
  function hide() {
    setVisible(false)
    onHide && onHide()
  }

  return createPortal(
    <div className={`aside-modal add-edit-post ${visible ? 'visible' : ''} ${size ? size : 'fc'}`}>
      <div className={'aside-modal-shadow'} onClick={() => hide()} />
      <div className="content">
        {title && (
          <div className="flex mt-4">
            <h2>{title}</h2>
          </div>
        )}
        <button
          className="close-btn without-title mr-4"
          onClick={() => {
            hide()
          }}
        >
          <img src="https://development.communityigniter.com/icons/close.svg" alt="Close" />
        </button>
        <div>{visible && children}</div>
      </div>
    </div>,
    document.getElementById('modal')
  )
}

export default AsideModal
